import React from "react";
import { styled } from "@mui/styles";
import MuiGrid from "@mui/material/Grid";
import MuiInputLabel from "@mui/material/InputLabel";
import MuiTextField from "@mui/material/TextField";
import MuiDivider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControlLabel, Input } from "@mui/material";
import IOSSwitch from "../../../../atoms/IOSSwitch";
import InputNumber from "../../../../atoms/InputNumber";
import { CreateMotoContext } from "../../../../../contexts/CreateMotoContext";
import GeneralData from "../../../../../data/GeneralData.js";

const Grid = styled(MuiGrid)({
  display: "flex",
  marginTop: 50,
  marginBottom: 20,
  justifyContent: "center",
});

const InputLabel = styled(MuiInputLabel)({
  marginRight: 10,
  alignSelf: "center",
});

const TextField = styled(MuiTextField)({
  width: "60%",
  marginRight: 10,
  "& > p": {
    fontSize: "1.2rem",
  },
});

const Divider = styled(MuiDivider)({
  width: "100%",
});

const InformationMoto = ({ marks, styles }) => {
  const defaultPropsMarca = {
    options: marks,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  const defaultPropsStyles = {
    options: styles,
    getOptionLabel: (option) => {
      if (option.hasOwnProperty("name")) {
        return option.name;
      }
      return option;
    },
  };

  const {
    values,
    onChange,
    onChangeMarca,
    onChangeEstilo,
    onChangeFoto,
    onChangeCheck,
    onChangeCheckOmnia
  } = React.useContext(CreateMotoContext);

  const [base64, setBase64] = React.useState();

  values.foto &&
    values.foto.name &&
    convertFileToBase64(values.foto).then((result) => {
      setBase64(result);
    });

  return (
    <Grid container style={{ margin: 0 }}>
      <Grid item xs={12}>
        <InputLabel htmlFor="input-name-moto">Nombre de moto</InputLabel>
        <TextField
          id="input-name-moto"
          name="nombre"
          variant="outlined"
          style={{
            marginLeft: 45,
          }}
          maxLength={55}
          value={values.nombre}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-short-description">
          Descripción corta
        </InputLabel>
        <TextField
          id="input-short-description"
          name="descripcion_corta"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          maxLength={160}
          helperText="Máximo de caracteres:300"
          style={{
            marginLeft: 47,
          }}
          value={values.descripcion_corta}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-model-moto">Modelo</InputLabel>
        <InputNumber
          id="input-model-moto"
          name="modelo"
          variant="outlined"
          style={{
            marginLeft: 115,
            width: "60%",
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          value={values.modelo}
          onChange={onChange}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel
          htmlFor="input-marca-moto"
          // style={{ width: "60px", position: "relative", left: 151 }}
        >
          Marca
        </InputLabel>
        <Autocomplete
          id="input-marca-moto"
          name="marca"
          noOptionsText="No se encuentran marcas"
          label="Seleccione la marca"
          data={marks}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value.name}
          {...defaultPropsMarca}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione la marca"
              margin="normal"
              variant="outlined"
            />
          )}
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 115,
            width: "60%",
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          fullWidth
          value={values.id_marca?.name || null}
          onChange={onChangeMarca}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-style-moto">Estilo</InputLabel>
        <Autocomplete
          id="input-style-moto"
          name="style-moto"
          noOptionsText="No se encuentran estilos"
          label="Seleccione el estilo"
          data={styles}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value.name}
          {...defaultPropsStyles}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione el estilo"
              margin="normal"
              variant="outlined"
            />
          )}
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 115,
            width: "60%",
            marginRight: 10,
            "& > p": {
              fontSize: "1.2rem",
            },
          }}
          fullWidth
          value={values.id_estilo?.name || null}
          onChange={onChangeEstilo}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-photo-moto">Foto principal</InputLabel>
        <Input
          id="input-photo-moto"
          name="photo-moto"
          variant="outlined"
          style={{
            marginLeft: 80,
          }}
          maxLength={55}
          type="file"
          inputProps={{ accept: "image/*" }}
          onChange={onChangeFoto}
        />
        <img
          src={
            !base64 ? `${GeneralData.host}/images${values.url_foto}` : base64
          }
          alt="moto selected"
          style={{
            width: 90,
            height: 50,
          }}
        />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <InputLabel htmlFor="input-show-moto"> Ocultar / Mostrar</InputLabel>
        <FormControlLabel
          id="input-show-moto"
          name="show-moto"
          control={<IOSSwitch sx={{ m: 1 }} />}
          label=""
          onChange={onChangeCheck}
          checked={values.mostrar_ocultar}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="input-show-moto-in-omnia"> Ocultar / Mostrar en Omnia</InputLabel>
        <FormControlLabel
          id="input-show-moto-in-omnia"
          name="show-moto-in-omnia"
          control={<IOSSwitch sx={{ m: 1 }} />}
          label=""
          onChange={onChangeCheckOmnia}
          checked={values.canShowInOmnia}
        />
      </Grid>
    </Grid>
  );
};
// function return base64 from file
function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default InformationMoto;
