/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@mui/material/Checkbox";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "./style.css";

export const ProductCar = ({ element, modalCar, setModalCar }) => {
  const colorInit = (item) => {
    let idColor = 0;
    item.MotosColores.forEach((color, index) => {
      if (color.Colore.codigo_color === item?.colorSelect) {
        idColor = index;
      }
    });
    return idColor;
  };
  const [indexColor, setIndexColor] = useState(
    element?.colorSelect ? colorInit(element) : 0
  );

  const handleColor = (index, item) => {
    setIndexColor(index);
    let product = modalCar.product.map((element) => {
      if (element.id === item.id) {
        element.colorSelect = item.MotosColores[index].Colore.codigo_color;
      }
      return element;
    });
    setModalCar((prevState) => ({
      ...prevState,
      product: product,
    }));
  };

  const handleCheck = (event, id) => {
    let product = modalCar.product.map((element) => {
      if (element.id === id) {
        element.buyMatr = event.target.checked;
      }
      return element;
    });
    setModalCar((prevState) => ({
      ...prevState,
      product: product,
    }));
  };

  const handleClose = (id) => {
    let product = modalCar.product;
    let idDelete;
    product.forEach((element, index) => {
      if (element.id === id) {
        idDelete = index;
      }
    });
    product.splice(idDelete, 1);
    setModalCar((prevState) => ({
      ...prevState,
      product: product,
    }));
  };

  return (
    <li className="c-list-imgCar__item">
      <div className="c-list-imgCar__box">
        <div className="c-list-12__imgCar">
          <div className="c-slider-2 js-slider-carrito">
            {element.MotosColores.map((image, index) => {
              return (
                <a
                  className={
                    indexColor === index ? "slide-active" : "c-slider-2__item"
                  }
                  href={
                    GeneralData.host + "/images" + image.url_foto_color_peque
                  }
                  key={index}
                >
                  <img
                    src={
                      GeneralData.host + "/images/" + image.url_foto_color_peque
                    }
                    alt={image.Colore.nombre_color}
                  />
                </a>
              );
            })}
          </div>
        </div>
        <div className="c-list-12__imgCar-2">
          <div className="c-list-12__info-imgCar">
            <div className="c-list-12__marca-imgCar">
              {element?.MotosMarca?.nombre_marca}
            </div>
            <div className="c-list-12__name-imgCar">{element.nombre}</div>
            <div className="c-list-12__modelo-imgCar">{element.modelo}</div>
          </div>
          <FontAwesomeIcon
            className="c-list-12__delete-imgCar"
            icon={faClose}
            onClick={() => handleClose(element.id)}
          />
        </div>
      </div>
      <div className="c-list-12__imgCar--v2">
        <div className="c-list-12__colors">
          <div className="c-list-7-imgCar">
            {element.MotosColores.map((color, index) => {
              return (
                <div
                  className={clsx(
                    "c-list-7__item  js-item-color",
                    indexColor === index && "slick-active"
                  )}
                  data-name-color={color.Colore.nombre_color}
                  key={index}
                  onClick={() => handleColor(index, element)}
                >
                  <div
                    className="c-list-5__color js-select-color"
                    style={{
                      backgroundColor: color.Colore.codigo_color,
                    }}
                    data-slick-index={indexColor}
                    key={indexColor}
                  >
                    <div
                      className="c-list-5__color-boder"
                      style={{ borderColor: color.Colore.codigo_color }}
                      key={indexColor}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="c-list-12__price-imgCar">
          {formatCurrency("es-CO", "COP", 1, element.precio_moto)}
        </div>
      </div>
      <div className="o-form-check-imgCar">
        <Checkbox
          style={{ margin: "0px", padding: "0px" }}
          size="large"
          checked={!!element?.buyMatr}
          onChange={(event) => handleCheck(event, element.id)}
          sx={{
            color: "#3e6fff",
            "&.Mui-checked": {
              color: "#f18c17",
            },
          }}
        />
        <span className="c-form-1__text-matricula">
          Incluir matricula y documentos
        </span>
      </div>
    </li>
  );
};
