import React from "react";

export const MotorTable = ({ motos, listallmotos }) => {
  return (
    <>
      <div className="c-table-1__header-2">Motor</div>
      <div className="c-table-1__box-2">
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Cilindraje</div>
                <div className="c-table-1__value">{moto1.cilindraje}</div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Tipo de motor</div>
                <div className="c-table-1__value">{moto1.tipo_motor}</div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Potencia máxima</div>
                <div className="c-table-1__value">{`${moto1.potencia_maxima_hp} HP @ ${moto1.potencia_maxima_rpm} rpm`}</div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Torque máximo</div>
                <div className="c-table-1__value">{`${moto1.torque_maximo_nm} HP @ ${moto1.torque_maximo_rpm} rpm`}</div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Relación de compresión</div>
                <div className="c-table-1__value">
                  {moto1.relacion_compresion}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Tipo de transmisión</div>
                <div className="c-table-1__value">
                  {moto1.MotosTransmision.nombre_transmision}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};
