import React, { useState, useContext } from "react";
import { CounterContext } from "../../../contexts/CounterContext";
import { ModalComparar } from "../../molecules/ModalComparar";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faImages,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "./style.css";

export const InfoProduct = ({ product }) => {
  const [indexColor, setIndexColor] = useState(0);
  const { comparador, setComparador, modalCar, setModalCar } =
    useContext(CounterContext);

  window.addEventListener("scroll", () => {
    let description = document.getElementById("description");
    if (description !== null) {
      if (window.scrollY > 117) {
        description.classList.add("staticDM");
      } else {
        document.getElementById("description").classList.remove("staticDM");
      }
    }
  });

  const handleColor = (index) => {
    setIndexColor(index);
  };

  const handleCar = () => {
    if (
      modalCar.product.find((element) => element.id === product.id) ===
      undefined
    ) {
      modalCar.product.push(product);
    }
    setModalCar((prevState) => ({
      ...prevState,
      state: true,
      product: modalCar.product,
    }));
  };

  const handleComparar = (moto) => {
    let motosComparador = comparador.product;
    if (motosComparador.map((elemnt) => elemnt.id).indexOf(moto.id) < 0) {
      motosComparador.length === 3 && motosComparador.shift();
      motosComparador.push(moto);
    }
    setComparador((prevState) => ({
      ...prevState,
      modal: true,
      product: motosComparador,
    }));
  };

  return (
    <>
      <div className="g-col-xl-8 c-block-5__pr1">
        <div className="c-card-3">
          <div className="c-card-3__img" key="gg">
            <div className="c-slider-2 js-slider-2 slick-initialized slick-slider">
              {product.MotosColores.map((element, index) => {
                return (
                  <a
                    className={
                      indexColor === index ? "slide-active" : "c-slider-2__item"
                    }
                    href={
                      GeneralData.host +
                      "/images" +
                      element.url_foto_color_grande
                    }
                    key={index}
                  >
                    <img
                      src={
                        GeneralData.host +
                        "/images/" +
                        element.url_foto_color_peque
                      }
                      alt={element.Colore.nombre_color}
                    />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="c-card-3__box">
            <div className="c-list-7 c-card-3__list" id="detalle-moto-color">
              {product.MotosColores.map((element, index) => {
                return (
                  <div
                    className={clsx(
                      "c-list-7__item  js-item-color",
                      indexColor === index && "slick-active"
                    )}
                    data-name-color={element.Colore.nombre_color}
                    key={index}
                    onClick={() => handleColor(index)}
                  >
                    <div
                      className="c-list-5__color js-select-color"
                      style={{ backgroundColor: element.Colore.codigo_color }}
                      data-slick-index={indexColor}
                      key={indexColor}
                    >
                      <div
                        className="c-list-5__color-boder"
                        style={{ borderColor: element.Colore.codigo_color }}
                        key={indexColor}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="c-card-3__col">
              {product.url_fotos_360 !== "" && (
                <div className="c-card-3__btn" onClick={() => handleModal()}>
                  <img src={GeneralData.host + "/images/360.svg"} alt="Ver 360" />
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
      <div className="g-col-xl-4 c-block-5__col-2">
        <div className="c-block-5__box-2 js-info-product" id="description">
          <div className="c-block-5__heading">
            <div className="o-text-2">{product?.MotosMarca?.nombre_marca}</div>
            <h2 className="o-heading-5">{product?.nombre}</h2>
            {product?.modelo !== "" && (
              <div className="o-text-3">{product?.modelo}</div>
            )}
          </div>
          <p className="c-block-5__p">{product?.descripcion_corta}</p>
          <div className="c-block-5__price">
            <div className="o-text-4">
              {formatCurrency("es-CO", "COP", 2, product?.precio_moto)}
            </div>
            <div className="o-text-5">IVA incluido</div>
          </div>
          <div className="c-block-5__btn-2">
            <button
              className="o-btn-3--v3 js-add-carrito"
              onClick={handleCar}
              style={{ minWidth: "160px" }}
            >
              <FontAwesomeIcon
                icon={faCartShopping}
                style={{ marginRight: "10px" }}
              />
              Comprar
            </button>
            <Link
              to={`/financiamiento/${product?.slug_moto}`}
              key={product?.id}
            >
              <button className="o-btn-4--v3" style={{ minWidth: "160px" }}>
                <FontAwesomeIcon
                  icon={faCreditCard}
                  style={{ marginRight: "10px" }}
                />
                Solicitar crédito
              </button>
            </Link>
            <button
              className="o-btn-7--v2 c-block-5__btn-comparar js-add-comparador"
              onClick={() => handleComparar(product)}
              style={{ minWidth: "160px" }}
            >
              <FontAwesomeIcon
                icon={faImages}
                style={{ marginRight: "10px" }}
              />
              Comparar
            </button>
          </div>
          <ModalComparar />
        </div>
      </div>
    </>
  );
};
