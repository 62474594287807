import React from "react";

export const CaracteristicasTable = ({ motos, listallmotos }) => {
  return (
    <>
      <div className="c-table-1__header-2">Características adicionales</div>
      <div className="c-table-1__box-2">
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Tipo de arranque</div>
                <div className="c-table-1__value">
                  {moto1.MotosTipoArranque.nombre_tipo_arranque}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Certificación</div>
                <div className="c-table-1__value">{moto1.certificacion}</div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Colores</div>
                <div className="c-table-1__value color">
                  {moto1.MotosColores.map((item) => (
                    <ul className="c-list-5" key={item.id}>
                      <li
                        style={{ backgroundColor: item.Colore.codigo_color }}
                      ></li>
                    </ul>
                  ))}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};
