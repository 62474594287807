import React, { useState, useContext } from "react";
import { CounterContext } from "../../../contexts/CounterContext";
import { Link } from "react-router-dom";
import { ButtonSelect } from "../../atoms/ButtonSelect/Index.jsx";
import { Button } from "../../atoms/Button/index.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faCartShopping,
  faImages,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import "../NavBar/style.css";
import { VerifyClientLogged } from "../../../utils/Permissions";
import Swal from "sweetalert2";
import { useLocation, useHistory } from "react-router-dom";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "../NavBar/style.css";

export const NavBar = () => {
  const { comparador, modalCar, setModalCar, setOpenBG } =
    useContext(CounterContext);
  //use to get location realtime
  let location = useLocation();
  //use to change directions
  const history = useHistory();

  //INITIALIZE STATES
  const [openMenuMotos, setOpenMenusMotos] = useState(false);
  const [openMenuSolicitud, setopenMenuSolicitud] = useState(false);
  const [openMenuCredito, setOpenMenuCredito] = useState(false);
  const [openMenuOthers, setOpenMenuOthers] = useState(false);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [openMP, setOpenMP] = useState(false);
  const [userLogged, setUserLogged] = React.useState({});

  //HANDLES CHANGES MENUS
  const handleMenuMotos = () => {
    setOpenMenusMotos(!openMenuMotos);
    setopenMenuSolicitud(false);
    setOpenMenuCredito(false);
    setOpenMenuOthers(false);
    setOpenMP(false);
  };

  const handleMenuSolicitud = () => {
    setopenMenuSolicitud(!openMenuSolicitud);
    setOpenMenusMotos(false);
    setOpenMenuCredito(false);
    setOpenMenuOthers(false);
    setOpenMP(false);
  };

  const handleMenuCredito = () => {
    setOpenMenuCredito(!openMenuCredito);
    setOpenMenusMotos(false);
    setopenMenuSolicitud(false);
    setOpenMenuOthers(false);
    setOpenMP(false);
  };

  const handleMenuOthers = () => {
    setOpenMenuOthers(!openMenuOthers);
    setOpenMenuCredito(false);
    setOpenMenusMotos(false);
    setopenMenuSolicitud(false);
    setOpenMP(false);
  };

  const handleMenuMobile = () => {
    setOpenMenuMobile(!openMenuMobile);
  };

  const handleMyPerfil = () => {
    setOpenMP(!openMP);
  };

  React.useEffect(() => {
    VerifyClientLogged()
      .then((res) => {
        setUserLogged(res.data);
      })
      .catch((err) => {
        if (pathsClientLogged.includes(location.pathname)) {
          Swal.fire({
            title: "Error!",
            text: "No tienes acceso a este menu",
            icon: "error",
            confirmButtonText: "Ok",
          }).then(() => history.push("/login"));
        }
      });
    // eslint-disable-next-line
  }, [location]);

  document.addEventListener("click", (e) => {
    //eslint-disable-next-line
    if (e.target.id != "MenusNavbar") {
      setOpenMenusMotos(false);
      setopenMenuSolicitud(false);
      setOpenMenuCredito(false);
      setOpenMenuOthers(false);
    }
  });

  return (
    <div id="body_container" className="c-body__container">
      <header className="c-header" id="barranav">
        <div className="g-container c-header__container">
          <div className="c-header__col">
            <Link to="/" className="c-header__logo">
              <img
                src={GeneralData.host + "/images/logo-supre@2x.png"}
                alt="Logo Supremotos"
              />
            </Link>
            <div className="c-header__main-menu">
              <ul className="c-nav-1">
                <li>
                  <div
                    id="MenusNavbar"
                    className="Menus"
                    onClick={handleMenuMotos}
                  >
                    Motos
                    <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
                  </div>
                  <ButtonSelect
                    className={clsx(
                      "c-nav-1__submenu",
                      openMenuMotos && "open"
                    )}
                    id="submenu-motos"
                    links={[
                      {
                        route: "/catalogo-de-motos",
                        name: "Catálogo de motos",
                      },
                      {
                        route: `${
                          comparador.product.length === 0
                            ? "/comparador-de-motos/"
                            : `/comparador-de-motos/?motos=${comparador.product
                                .map((elemnt) => elemnt.id)
                                .join(",")}`
                        }`,
                        name: "Comparar motos",
                      },
                      { route: "#", name: "Búsqueda guiada", id: "BG" },
                    ]}
                    setUserLogged={setUserLogged}
                    setClose={setOpenMenusMotos}
                    setOpenBG={setOpenBG}
                  />
                </li>
                <li>
                  <div
                    onClick={handleMenuSolicitud}
                    id="MenusNavbar"
                    className="Menus"
                  >
                    Solicitud de crédito
                    <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
                  </div>
                  <ButtonSelect
                    className={clsx(
                      "c-nav-1__submenu",
                      openMenuSolicitud && "open"
                    )}
                    id="submenu-financiamiento"
                    setClose={setopenMenuSolicitud}
                    links={[{ route: "/financiamiento/", name: "Motos" }]}
                  />
                </li>
                <li>
                  <div
                    onClick={handleMenuCredito}
                    id="MenusNavbar"
                    className="Menus"
                  >
                    Tu crédito
                    <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
                  </div>
                  <ButtonSelect
                    className={clsx(
                      "c-nav-1__submenu",
                      openMenuCredito && "open"
                    )}
                    id="submenu-tucredito"
                    setClose={setOpenMenuCredito}
                    links={[
                      { route: "/paga-tu-cuota/", name: "Paga tu cuota" },
                      { route: "/requerimientos/", name: "Requerimientos" },
                    ]}
                  />
                </li>
                <li>
                  <div
                    onClick={handleMenuOthers}
                    id="MenusNavbar"
                    className="Menus"
                  >
                    Otros
                    <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
                  </div>
                  <ButtonSelect
                    className={clsx(
                      "c-nav-1__submenu",
                      openMenuOthers && "open"
                    )}
                    id="submenu-preguntas"
                    setClose={setOpenMenuOthers}
                    links={[
                      {
                        route: "/preguntas-frecuentes/",
                        name: "Preguntas frecuentes",
                      },
                       {
                         route: "/general-blog/",
                         name: "Blog",
                       },
                    ]}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="c-header__col colm2">
            <ul className="c-nav-1--v2">
              <li>
                <Link
                  to={
                    comparador.product.length === 0
                      ? "/comparador-de-motos/"
                      : `/comparador-de-motos/?motos=${comparador.product
                          .map((elemnt) => elemnt.id)
                          .join(",")}`
                  }
                  className="c-list4__compare icon-image-compare"
                >
                  <FontAwesomeIcon
                    className="nav-icons"
                    id="IconsRight"
                    icon={faImages}
                  />
                  {comparador.product.length > 0 && (
                    <span className="c-nav-2__notify" id="count-carrito">
                      {comparador.product.length}
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <div
                  className="Menus"
                  onClick={() => {
                    setModalCar((prevState) => ({
                      ...prevState,
                      state: true,
                    }));
                  }}
                >
                  <FontAwesomeIcon
                    className="nav-icons"
                    id="IconsRight"
                    icon={faCartShopping}
                  />
                  {modalCar.product.length > 0 && (
                    <span className="c-nav-2__notify" id="count-carrito">
                      {modalCar.product.length}
                    </span>
                  )}
                </div>
              </li>
            </ul>
            {Object.entries(userLogged).length !== 0 ? (
              <div className="c-header-m__btns" onClick={handleMyPerfil}>
                <ul className="c-nav-1-m">
                  <li>
                    <div className="Menus" id="mi_perfil">
                      Mi perfil
                      <FontAwesomeIcon
                        className="nav-icons"
                        icon={faAngleDown}
                      />
                    </div>
                    <ButtonSelect
                      setOpenMP={setOpenMP}
                      setUserLogged={setUserLogged}
                      className={clsx("c-nav-1__submenu", openMP && "open")}
                      id="submenu-perfil"
                      setClose={setOpenMP}
                      links={[
                        {
                          route: "/perfil",
                          name: "Mis ordenes",
                        },
                        {
                          route: "/perfil/editar/",
                          name: "Editar pefil",
                        },
                        {
                          route: "/login",
                          name: "Cerrar sesión",
                        },
                      ]}
                    />
                  </li>
                </ul>
              </div>
            ) : (
              <div className="c-header__btns">
                <Button
                  className={"o-btn-2"}
                  link={"/login/"}
                  name={"Ingresar"}
                />
                <Button
                  className={"o-btn"}
                  link={"/login/registro/"}
                  name={"Regístrate"}
                />
              </div>
            )}
            <div
              className="c-header__btn-mobile"
              id="open"
              onClick={handleMenuMobile}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
        </div>
      </header>
      <div className={clsx("c-nav-mobile", openMenuMobile && "open")}>
        <div className="c-nav-mobile__container">
          {Object.entries(userLogged).length === 0 && (
            <div className="c-nav-mobile__btns">
              <div className="o-btn-2--v2">
                <Link to={"/login/"} onClick={handleMenuMobile}>
                  Ingresar
                </Link>
              </div>
              <div className="o-btn Menus">
                <Link to={"/login/registro/"} onClick={handleMenuMobile}>
                  Regístrate{" "}
                </Link>
              </div>
            </div>
          )}
          <ul className="c-nav-3">
            <li>
              <div
                className="Menus_mobile"
                id="MenusNavbar"
                onClick={handleMenuMotos}
              >
                Motos
                <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
              </div>
              <ButtonSelect
                setOpenBG={setOpenBG}
                className={clsx("c-nav-3__submenu", openMenuMotos && "open")}
                id="submenu-motos2"
                setClose={setOpenMenuMobile}
                links={[
                  { route: "/catalogo-de-motos", name: "Catálogo de motos" },
                  {
                    route: `${
                      comparador.product.length === 0
                        ? "/comparador-de-motos/"
                        : `/comparador-de-motos/?motos=${comparador.product
                            .map((elemnt) => elemnt.id)
                            .join(",")}`
                    }`,
                    name: "Comparar motos",
                  },
                  {
                    route: "#",
                    name: "Búsqueda guiada",
                    id: "BG",
                  },
                ]}
              />
            </li>
            <li>
              <div
                className="Menus_mobile"
                id="MenusNavbar"
                onClick={handleMenuSolicitud}
              >
                Solicitud de crédito
                <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
              </div>
              <ButtonSelect
                className={clsx(
                  "c-nav-3__submenu",
                  openMenuSolicitud && "open"
                )}
                id="submenu-financiamiento2"
                setClose={setOpenMenuMobile}
                links={[{ route: "/financiamiento/", name: "Moto" }]}
              />
            </li>
            <li>
              <div
                className="Menus_mobile"
                id="MenusNavbar"
                onClick={handleMenuCredito}
              >
                Tu Crédito
                <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
              </div>
              <ButtonSelect
                className={clsx("c-nav-3__submenu", openMenuCredito && "open")}
                id="submenu-tucredito3"
                setClose={setOpenMenuMobile}
                links={[
                  { route: "/paga-tu-cuota/", name: "Paga tu cuota" },
                  { route: "/requerimientos/", name: "Requerimientos" },
                ]}
              />
            </li>
            <li>
              <div
                className="Menus_mobile"
                id="MenusNavbar"
                onClick={handleMenuOthers}
              >
                Otros
                <FontAwesomeIcon className="nav-icons" icon={faAngleDown} />
              </div>
              <ButtonSelect
                className={clsx("c-nav-3__submenu", openMenuOthers && "open")}
                id="submenu-preguntas2"
                setClose={setOpenMenuMobile}
                links={[
                  {
                    route: "/preguntas-frecuentes/",
                    name: "Preguntas frecuentes",
                  },
                  {
                    route: "/general-blog/",
                    name: "Blog",
                  },
                ]}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// paths avalaible for credit_module
const pathsClientLogged = [
  "/perfil/",
  "/perfil/link-afiliado/",
  "/perfil/editar/",
];
