import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { GetFilterMotos } from "../../../utils/AllMotos";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "./style.css";

export const ModalListMotos = ({ openSearch, setOpenSearch, handleSelect }) => {
  const [listFilterMotos, setListFilterMotos] = useState([]);
  const [listAllMotos, setListAllMotos] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    GetFilterMotos().then((res) => {
      setListAllMotos(res.data);
    });
  }, []);

  useEffect(() => {
    setListFilterMotos(listAllMotos);
    setFilter("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSearch]);

  const filterName = () => {
    let name = document.getElementById("keyword").value;
    setFilter(name);
    setListFilterMotos(
      listAllMotos.filter((motos) =>
        motos.nombre.toLowerCase().includes(name.toLowerCase())
      )
    );
  };

  const handleClose = (data) => {
    setOpenSearch(!openSearch);
    handleSelect(data);
  };

  return (
    <div className={clsx("c-modal-1", openSearch && "open")} id="modal-filtros">
      <div className="c-modal-1__header">
        <div className="c-modal-1__header-bar">
          <div className="c-modal-1__header-title">Agregar moto</div>
          <div className="c-modal-1__header-close">
            <button
              className="c-modal-1__close js-close-filtros"
              onClick={handleClose}
            >
              <FontAwesomeIcon className="icon-cross2" icon={faXmark} />
              Cerrar
            </button>
          </div>
        </div>
        <div className="c-modal-1__tags-filter--v2">
          <div className="c-modal-1__section-2">
            <div className="c-modal-1__item">
              <div className="c-modal-1__row">
                <label className="o-form-label">Buscar moto</label>
                <div className="c-modal-1__field">
                  <input
                    className="o-form-field--v2"
                    type="text"
                    id="keyword"
                    value={filter}
                    onChange={() => filterName()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-modal-1__content ps">
        <div id="lista-motos">
          <div className="c-list-10  js-listado-motos">
            <div className="jscroll-inner">
              {listFilterMotos.map((item) => (
                <div className="c-list-10__item" key={item.id}>
                  <div className="c-list-10__hover">
                    <button
                      className="o-btn-3 c-list-10__btn"
                      onClick={() => handleClose(item)}
                    >
                      Seleccionar
                    </button>
                  </div>
                  <div className="c-list-10__img">
                    <img
                      src={`${GeneralData.host}/images${item.url_foto}`}
                      alt={item.nombre}
                    />
                  </div>
                  <div className="c-list-10__box">
                    <div className="c-list-10__name">{item.nombre}</div>
                    <div className="c-list-10__brand">
                      {item.MotosMarca.nombre_marca}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
