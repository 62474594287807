import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTiktok,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import GeneralData from "../../../data/GeneralData.js";
import "../Footer/style.css";

export const Footer = () => {
  return (
    <>
      <footer className="c-footer">
        <div className="g-container2 c-footer__container">
          <div className="c-footer__col">
            <Link to="/" className="c-footer__logo">
              <img
                src={GeneralData.host + "/images/logo-supre@2x.png"}
                alt="Logo Supremotos - footer"
              />
            </Link>
            <ul className="c-nav-2 c-footer__nav">
              <li>
                <Link to="/quienes-somos/">¿Quiénes somos?</Link>
              </li>
              <li>
                <Link to="/politicas/">Términos y condiciones</Link>
              </li>
              <li>
                <Link to="/contacto/"> Contáctanos </Link>
              </li>
              <li>
                <Link to="/preguntas-frecuentes/"> Preguntas frecuentes </Link>
              </li>
            </ul>
          </div>
          <div className="c-footer__col c-footer__colPages">
            <ul className="c-nav-4">
              <li>
                <a
                  className="icon-instagram"
                  href="https://wa.me/573012064520"
                  target="blank"
                >
                  <FontAwesomeIcon className="nav-icons" icon={faWhatsapp} />
                  <span> WhatsApp </span>
                </a>
              </li>
              <li>
                <a
                  className="icon-instagram"
                  href="https://www.instagram.com/supre_motos/"
                  target="blank"
                >
                  <FontAwesomeIcon className="nav-icons" icon={faInstagram} />
                  <span> Instagram </span>
                </a>
              </li>
              <li>
                <a
                  className="icon-facebook"
                  href="https://www.facebook.com/supregroup/"
                  target="blank"
                >
                  <FontAwesomeIcon className="nav-icons" icon={faFacebookF} />
                  <span> Facebook </span>{" "}
                </a>
              </li>
              <li>
                <a
                  className="icon-tiktok"
                  href="https://www.tiktok.com/@supre_motos"
                  target="blank"
                >
                  <FontAwesomeIcon className="nav-icons" icon={faTiktok} />
                  <span> TikTok </span>
                </a>
              </li>
              <li>
                <a
                  className="icon-youtube"
                  href="https://www.youtube.com/channel/UCNXbDS5Q8e8ElGinGfjz2Mg"
                  target="blank"
                >
                  <FontAwesomeIcon className="nav-icons" icon={faYoutube} />
                  <span> YouTube </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};
