import React from "react";

export const SeguridadTable = ({ motos, listallmotos }) => {
  return (
    <>
      <div className="c-table-1__header-2">Seguridad</div>
      <div className="c-table-1__box-2">
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Freno delantero</div>
                <div className="c-table-1__value">
                  {moto1.MotosFrenoDelantero.nombre_freno_delantero}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">Freno trasero</div>
                <div className="c-table-1__value">
                  {moto1.MotosFrenoTrasero.nombre_freno_trasero}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  Tipo de suspensión delantera
                </div>
                <div className="c-table-1__value">
                  {moto1.MotosSuspensionDelantera.nombre_suspension_delantera}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
        {[0, 1, 2].map((element) => {
          if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
            //eslint-disable-next-line
            let moto1 = listallmotos.find((moto) => moto.id == motos[element]);
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  Tipo de suspension trasera
                </div>
                <div className="c-table-1__value">
                  {moto1.MotosSuspensionTrasera.nombre_suspension_trasera}
                </div>
              </div>
            );
          } else {
            return (
              <div className="c-table-1__item-2" key={element}>
                <div className="c-table-1__title">
                  <div className="c-table-1__vacio-1"></div>
                </div>
                <div className="c-table-1__value">
                  <div className="c-table-1__vacio-2"></div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
};
