import React, { useEffect, useState } from "react";
import { SliderHome } from "../components/molecules/SliderHome";
import { BusquedaGuiada } from "../components/molecules/BusquedaGuiada";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getIpClient } from "../helpers/GetIp";
import { referralHistoryService } from "../utils/save-referrall-history";

export const HomePage = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const referido = searchParams.get("referido");
  referido && sessionStorage.setItem("referido", referido);

  const saveReferralHistory = async () => {
    //Get user ip
    await getIpClient()
      .then((result) => {
        if (result) {
          referralHistoryService(result, referido);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (referido) {
      saveReferralHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referido]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bienvenidos - Supre</title>
        <meta name="description" content="Supre pagina oficial" />
      </Helmet>
      <SliderHome />
      <BusquedaGuiada />
    </>
  );
};
