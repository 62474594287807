import React from "react";
import GeneralData from "../../../data/GeneralData.js";
import "../BtnWap/style.css";

export const BtnWap = () => {
  return (
    <>
      <div className="o-btn-whatsapp">
        <a
          className="o-btn-whatspp__container"
          href="https://wa.me/573012064520"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={GeneralData.host + "/images/icon-whatsapp.svg"}
            alt="Contactar por WhatsApp"
          />
          <span className="o-btn-whatsapp__onda onda1"></span>
          <span className="o-btn-whatsapp__onda onda2"></span>
        </a>
      </div>
    </>
  );
};
