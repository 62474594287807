import React from "react";
import Helmet from "react-helmet";
import { Portada } from "../components/molecules/Portada";
import { VisionMision } from "../components/atoms/VisionMision";
import GeneralData from "../data/GeneralData.js";
import "../css/QuienesSomos.css";

export const QuienesSomos = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>¿Quiénes somos? - Supre</title>
        <meta name="description" content="¿Quiénes somos?" />
      </Helmet>
      <Portada className="c-block-3 QS" Name="¿Quiénes somos?" />
      <div className="c-block-5--v2">
        <div className="g-containerQS">
          <div className="c-card-2 c-block-5__m1">
            <picture className="c-card-2__img">
              <img
                src={GeneralData.host + "/images/quienes-somos.jpg"}
                alt="Quiénes Somos - Supre"
              />
            </picture>
            <p>
              Somos una empresa dedicada al otorgamiento de créditos, que
              hacemos parte de un equipo de personas que reconocemos la
              importancia de impulsar a otros a alcanzar sus sueños, por eso
              trabajamos día a día para contribuir al cumplimiento de los tuyos.
              Queremos ayudarte a adquirir todo lo que mereces, de forma ágil y
              sin complicaciones.
            </p>
            <p style={{ color: "white" }}>
              <strong>¡Crecer para ayudar a crecer!</strong>
            </p>
          </div>
          <h2 className="o-heading-3 c-block-5__m2">Propósitos Supre</h2>
          <div className="g-row c-block-5__box">
            <div className="col-l-6-mision">
              <VisionMision
                title="Visión"
                parragraph="Seremos reconocidos en el mercado crediticio por adaptarnos rápidamente a las necesidades de nuestros clientes y por ofrecer un servicio diferenciador."
                orientity="right"
              />
            </div>
            <div className="col-l-6-vision">
              <VisionMision
                title="Misión"
                parragraph="Buscamos dar soluciones efectivas de crédito, que se adapten a tus requerimientos. Impulsándote a alcanzar tus sueños y metas."
                orientity="left"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
