import React, { useContext } from "react";
import { CounterContext } from "../../../contexts/CounterContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";

export const BtnsTable = ({ motos, listallmotos }) => {
  const { modalCar, setModalCar } = useContext(CounterContext);

  const handleCar = (product) => {
    if (
      modalCar.product.find((element) => element.id === product.id) ===
      undefined
    ) {
      modalCar.product.push(product);
    }
    setModalCar((prevState) => ({
      ...prevState,
      state: true,
      product: modalCar.product,
    }));
  };

  return (
    <div className="c-list-9">
      {[0, 1, 2].map((element) => {
        if ((motos[element] !== undefined) & (listallmotos.length > 0)) {
          let moto1 = listallmotos.find(
            (moto) => moto.id.toString() === motos[element].toString()
          );
          return (
            <div className="c-list-9__item" key={element}>
              <div className="c-list-9__btns--v2">
                <button
                  className="c-list-9__btn-1 "
                  onClick={() => handleCar(moto1)}
                >
                  <FontAwesomeIcon
                    className="icon-cart-add"
                    icon={faCartPlus}
                  />
                  Comprar
                </button>
                <Link
                  to={`/financiamiento/${moto1?.slug_moto}`}
                  key={moto1?.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 5px",
                    padding: "0px",
                  }}
                >
                  <button
                    className="c-list-9__btn-2"
                    style={{ padding: "0px 30px" }}
                  >
                    Solicitar crédito
                  </button>
                </Link>
              </div>
            </div>
          );
        } else {
          return <div key={element}></div>;
        }
      })}
    </div>
  );
};
