import React, { useContext } from "react";
import { CounterContext } from "../../../contexts/CounterContext";
import GeneralData from "../../../data/GeneralData.js";
import "../BusquedaGuiada/style.css";

export const BusquedaGuiada = () => {
  const { setOpenBG } = useContext(CounterContext);
  // con esta función abriremos el apartado de la busqueda guiada.
  const handleBusquedaGuiada = () => {
    setOpenBG(true);
  };

  return (
    <>
      <div className="c-block-2">
        <div className="g-container c-block-2__container">
          <h2 className="o-heading-1 c-block-2__heading">Búsqueda guiada</h2>
          <p className="o-text-bg c-block-2__separe">Encuentra tu moto ideal</p>
          <div className="c-block-2__card">
            <div className="c-card-1">
              <div className="c-card-1__container">
                <div className="c-card-1__img">
                  <img
                    src={GeneralData.host + "/images/supre-bot.png"}
                    alt="Bot Supre"
                  />
                </div>
                <p className="c-card-1__text-1">
                  <strong>¡Hola!</strong> 👋
                </p>
                <p>
                  Contesta tres preguntas sencillas, para ayudarte a encontrar
                  la moto perfecta para ti.
                </p>
                <p>¿Comenzamos?</p>
              </div>
              <button onClick={handleBusquedaGuiada} className={"o-btn-4--v2"}>
                ¡Sí, vamos!
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
