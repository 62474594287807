import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "../SliderHome/style.css";

export const SliderHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  //use to change directions
  const history = useHistory();

  const bannerList = [
    {
      src: GeneralData.host + "/images/slider-1/banner-1.png",
      alt: "catalogo-de-motos",
      onClick: (event) => goToDirection(event, "/catalogo-de-motos"),
    },
    {
      src: GeneralData.host + "/images/slider-1/banner-2.png",
      alt: "financiamiento",
      onClick: (event) => goToDirection(event, "/financiamiento"),
    },
    {
      src: GeneralData.host + "/images/slider-1/banner-3.png",
      alt: "paga-tu-cuota",
      onClick: (event) => goToDirection(event, "/paga-tu-cuota"),
    },
  ];

  const handleSlider = useCallback(
    (direction) => {
      const newIndex =
        direction === "left"
          ? (currentIndex - 1 + bannerList.length) % bannerList.length
          : (currentIndex + 1) % bannerList.length;

      setCurrentIndex(newIndex);
    },
    [currentIndex, bannerList.length]
  );

  const handleAutoSlide = useCallback(() => {
    const interval = setInterval(() => {
      handleSlider("right");
    }, 10000);

    return () => clearInterval(interval);
  }, [handleSlider]);

  useEffect(() => {
    const autoSlide = handleAutoSlide();
    return () => autoSlide();
  }, [handleAutoSlide]);

  const goToDirection = (event, location) => {
    event.preventDefault();
    history.push(location);
  };

  return (
    <section className="carousel" aria-label="Gallery">
      <ol className="carousel__viewport">
        {bannerList.map((banner, index) => (
          <li
            key={index}
            className={clsx(
              "carousel__slide",
              index === currentIndex && "active"
            )}
          >
            <div className="carousel__snapper">
              <a href="/">
                <img
                  className={clsx(
                    "c-slider-1__img-movil",
                    index === currentIndex && "active"
                  )}
                  src={banner.src}
                  alt={banner.alt}
                  onClick={banner.onClick}
                />
              </a>
            </div>
          </li>
        ))}
        <FontAwesomeIcon
          className="Iconleft"
          icon={faAngleLeft}
          onClick={() => handleSlider("left")}
        />
        <FontAwesomeIcon
          className="Iconright"
          icon={faAngleRight}
          onClick={() => handleSlider("right")}
        />
      </ol>
    </section>
  );
};
