/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../../helpers/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import GeneralData from "../../../data/GeneralData.js";
import clsx from "clsx";
import "./style.css";

export const CardProduct = ({ product, setValues }) => {
  const [indexColor, setIndexColor] = useState(0);
  const history = useHistory();

  const handleColor = (index) => {
    setIndexColor(index);
    setValues((prevState) => ({
      ...prevState,
      color_moto: product?.MotosColores[index].Colore.nombre_color,
    }));
  };

  return (
    <div className="c-list-4__item">
      <div className="c-card-image">
        <div className="c-card-3__img" key="1">
          <div className="c-slider-2 js-slider-2 slick-initialized slick-slider">
            {product.MotosColores.map((element, index) => {
              return (
                <a
                  className={
                    indexColor === index ? "slide-active" : "c-slider-2__item"
                  }
                  href={
                    GeneralData.host + "/images" + element.url_foto_color_grande
                  }
                  key={index}
                >
                  <img
                    src={
                      GeneralData.host +
                      "/images" +
                      element.url_foto_color_peque
                    }
                    alt={element.Colore.nombre_color}
                  />
                </a>
              );
            })}
          </div>
        </div>
        <div className="c-info__box">
          <div className="c-info__heading">
            <div className="c-info__box-2">
              <div className="c-info__box-2-close">
                <div className="c-info__name">{product.nombre}</div>
                <div
                  className="c-info__name"
                  onClick={() => history.push("/financiamiento/")}
                >
                  <FontAwesomeIcon className="c-info__icon" icon={faClose} />
                </div>
              </div>
              <div className="c-info__brand">
                {product.MotosMarca.nombre_marca}
              </div>
            </div>
          </div>
          <div className="c-list-4__box-3" id="detalle-moto-color">
            <div className="c-list-4__colors">
              <div className="c-list-7">
                {product.MotosColores.map((element, index) => {
                  return (
                    <div
                      className={clsx(
                        "c-list-7__item  js-item-color",
                        indexColor === index && "slick-active"
                      )}
                      data-name-color={element.Colore.nombre_color}
                      key={index}
                      onClick={() => handleColor(index)}
                    >
                      <div
                        className="c-list-5__color js-select-color"
                        style={{ backgroundColor: element.Colore.codigo_color }}
                        data-slick-index={indexColor}
                        key={indexColor}
                      >
                        <div
                          className="c-list-5__color-boder"
                          style={{ borderColor: element.Colore.codigo_color }}
                          key={indexColor}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="c-info__price">
                {formatCurrency("es-CO", "COP", 2, product.precio_moto)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
