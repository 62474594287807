import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  TextField,
  Divider,
  Box,
} from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import GeneralData from "../../../../data/GeneralData.js";

export const EditGallery = ({
  showModal,
  setShowMondal,
  handleClick,
  data,
}) => {
  const [initialValue, setInitialValue] = React.useState(showModal?.data);

  const [selectedImage, setSelectedImage] = React.useState({
    smallimage: null,
    hugeimage: null,
  });

  const [imageUrl, setImageUrl] = React.useState({
    url_small: initialValue?.url_foto_peque?.includes("source")
      ? GeneralData.host + initialValue?.url_foto_peque
      : GeneralData.host + "/images" + initialValue?.url_foto_peque,
    url_huge: initialValue?.url_foto_grande?.includes("source")
      ? GeneralData.host + initialValue?.url_foto_grande
      : GeneralData.host + "/images" + initialValue?.url_foto_grande,
  });

  React.useEffect(() => {
    if (selectedImage.smallimage) {
      setImageUrl((prevState) => ({
        ...prevState,
        url_small: URL.createObjectURL(selectedImage.smallimage),
      }));
    }
    if (selectedImage.hugeimage) {
      setImageUrl((prevState) => ({
        ...prevState,
        url_huge: URL.createObjectURL(selectedImage.hugeimage),
      }));
    }
  }, [selectedImage]);

  const handleChange = (e) => {
    if (e.target.name === "url_foto_peque") {
      setSelectedImage((prevState) => ({
        ...prevState,
        smallimage: e.target.files[0],
      }));
    }
    if (e.target.name === "url_foto_grande") {
      setSelectedImage((prevState) => ({
        ...prevState,
        hugeimage: e.target.files[0],
      }));
    }
    const { value, name } = e.target;
    setInitialValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Dialog
      open={showModal.show}
      keepMounted
      onClose={ExitModal}
      fullWidth
      maxWidth="xs"
    >
      <Typography
        style={{ fontSize: "41px", margin: "16px 24px" }}
        variant="h1"
        gutterBottom
      >
        {showModal.data ? "Editar imagen " : "Crear imagen "}
      </Typography>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Descripción imagen (SEO)"
              name="descripcion"
              fullWidth
              value={initialValue?.descripcion}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Orden"
              name="orden"
              fullWidth
              value={initialValue?.orden}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              accept="image/*"
              type="file"
              id="select-image"
              style={{ display: "none" }}
              name="url_foto_peque"
              onChange={handleChange}
            />
            <label htmlFor="select-image">
              <Button
                variant="contained"
                color="primary"
                component="span"
                fullWidth
                style={{ height: 45 }}
              >
                <p style={{ fontSize: 14 }}> Cargar imagen pequeña </p>
              </Button>
            </label>
            <Box mt={2} textAlign="center">
              {showModal?.data || initialValue?.url_foto_peque ? (
                <span style={{ height: 55, width: 55 }}>
                  Tamaño de la imagen recomendado:{" "}
                  <span style={{ color: "blue" }}> 720 x 500 px </span>
                  <img
                    src={imageUrl.url_small}
                    alt="foto_url_pequeña"
                    style={{ paddingTop: 8, height: 50, width: 70 }}
                  />
                </span>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              accept="image/*"
              type="file"
              id="select-image2"
              style={{ display: "none" }}
              name="url_foto_grande"
              onChange={handleChange}
            />
            <label htmlFor="select-image2">
              <Button
                variant="contained"
                color="primary"
                component="span"
                fullWidth
                style={{ height: 45 }}
              >
                <p style={{ fontSize: 14 }}> Cargar imagen Grande </p>
              </Button>
            </label>
            <Box mt={2} textAlign="center">
              {showModal?.data || initialValue?.url_foto_grande ? (
                <span style={{ height: 55, width: 55 }}>
                  Tamaño de la imagen recomendado:
                  <span style={{ color: "blue" }}> 1800 x 1250 px </span>
                  <img
                    src={imageUrl.url_huge}
                    alt="foto_url_pequeña"
                    style={{ paddingTop: 8, height: 50, width: 70 }}
                  />
                </span>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider style={{ marginBottom: "10px" }} />
      <DialogActions>
        <Button
          size="medium"
          variant="contained"
          style={{ height: 40 }}
          onClick={() => {
            if (initialValue) {
              handleClick(initialValue, selectedImage);
            }
          }}
        >
          <SaveIcon style={{ fontSize: 25 }} />
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="error"
          onClick={ExitModal}
          style={{ height: 40 }}
        >
          <ExitToApp style={{ fontSize: 25 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
  function ExitModal(e) {
    setShowMondal({
      show: false,
      data: {},
    });
  }
};
