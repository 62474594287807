import GeneralData from "../GeneralData.js";

export var htmlNewPassword = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="es">

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <title>Recuperar Password</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">

    </head>

    <body style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; margin: 0; padding: 0;">
        <table width="100%" cellpadding="0" cellspacing="0" bgcolor="#ebeff2" class="full-content" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #ebeff2; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
            <tr>
                <td height="35" class="texto-inicial" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #ebeff2; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
            </tr>
            <tr>
                <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                    <table cellpadding="0" cellspacing="0" align="center" class="contenedor" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; margin: 0 auto; max-width: 600px; width: 90%;">
                        <tr>
                            <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                <!-- header -->
                                <table width="100%" cellpadding="0" cellspacing="0" class="header" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 100%;">
                                    <tr>
                                        <td class="header__conten-logo" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #fff; border-radius: 5px 5px 0 0; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 20px;"/images/logo-supre-dark-email@2x.png" width="150" alt="Logo Supre" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                    </tr>
                                </table>
                                <!-- / header -->
                                <!-- Bloque blanco-->
                                <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                    <tr>
                                        <td class="bloque-blanco" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #fff; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                            <table cellpadding="0" cellspacing="0" align="center" class="contenido" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 0 26px 0 26px; width: 100%;">
                                                <tr>
                                                    <td height="10" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                                        <!-- Contenido -->
                                                        <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                                            <tr>
                                                                <td height="20" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center" valign="top" class="titulo1" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #1f1f20; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 33px; font-weight: 500; line-height: 1.3;">!Hola @nombres!</td>
                                                            </tr>
                                                            <tr>
                                                                <td height="4" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center" valign="top" class="texto-19" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 19px; font-weight: 400; line-height: 1.3;">Para recuperar tu contraseña haz clic en el siguiente botón.</td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center" valign="top" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"><a href="${GeneralData.domain}/login/new-password/@codigo_registro/" class="boton--mediano-naranja" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #005ea6; border-radius: 5px; color: #fff; display: inline-block; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 500; padding: 12px 25px; text-decoration: none;">Recuperar contraseña</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="25" class="borde-separador" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; border-bottom: 1px solid #e6e9eb; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="25" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center" valign="top" class="texto-17" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 17px; font-weight: 400; line-height: 1.3;">¿No has solicitado cambiar tu contraseña en supre.com.co?<br> No te preocupes, solo ignora este mensaje.</td>
                                                            </tr>
                                                            <tr>
                                                                <td height="35" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                            </tr>
                                                        </table>
                                                        <!-- / Contenido -->
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <!-- / Bloque blanco -->
                                <!-- Footer -->
                                <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                    <tr>
                                        <td class="footer" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; background: #f7fafc; border-radius: 0 0 5px 5px; border-top: 1px solid #edf0f2; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                            <table cellpadding="0" cellspacing="0" align="center" class="contenido" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; padding: 0 26px 0 26px; width: 100%;">
                                                <tr>
                                                    <td height="30" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                                <tr>
                                                    <td class="footer__co1" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 60%;">
                                                        <table width="100%" cellpadding="0" cellspacing="0" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">
                                                            <tr>
                                                                <td style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;">Cordialmente,</td>
                                                            </tr>
                                                            <tr>
                                                                <td height="5" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 500;">Supre</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td valign="middle" class="footer__co2" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; width: 40%;"></td>
                                                </tr>
                                                <tr>
                                                    <td height="30" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <!-- / Footer -->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td height="50" style="-moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; color: #6d757c; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;"></td>
            </tr>
        </table>
    </body>

</html>`;
