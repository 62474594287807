import React from "react";
import { useChangeBackground } from "../hooks/useChangeBackground";
import useVerifyMenu from "../hooks/useVerifyMenu";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AdminMui from "../components/Masters/AdminMui";
import { useChangeTitleAppBar } from "../hooks/useChangeTitleAppBar";
import { ListCostos, UpdateCostos } from "../utils/CostosFinanciamiento";
import Swal from "sweetalert2";
import CostoTable from "../components/Organisms/CostosFinanciamiento/CostosTable";
import EditCost from "../components/Organisms/CostosFinanciamiento/EditCostos";

const CostosFinanciamiento = () => {
  const back = useVerifyMenu();
  const history = useHistory();
  const [costos_json, setCostos_Json] = React.useState([]); // Cargar los datos
  const [againFetch, setAgainFetch] = React.useState(false);
  const [showModal, setShowMondal] = React.useState({
    show: false,
    data: {},
  });

  useChangeBackground("#f5f5f8");
  useChangeTitleAppBar("Configuración de costos de financiamiento");

  React.useEffect(() => {
    ListCostos()
      .then((res) => setCostos_Json(res.data))
      .catch((err) => {
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }, [againFetch]);

  const handleClick = (costos) => {
    UpdateCostos(costos)
      .then((res) => {
        setShowMondal({
          show: false,
          data: {},
        });
        Swal.fire({
          title: "Proceso Ejecutado exitosamente!",
          text: res.message || "",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => setAgainFetch(!againFetch));
      })
      .catch((err) => {
        setShowMondal({
          show: false,
          data: {},
        });
        Swal.fire({
          title: "Error!",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return !back ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Configuración de costos de financiamiento - Supre</title>
        <meta
          name="description"
          content="Configuracion de costos de financiamiento - admin supre"
        />
      </Helmet>

      <AdminMui>
        <CostoTable costos_json={costos_json} setShowMondal={setShowMondal} />
        {showModal.show && (
          <EditCost
            showModal={showModal}
            setShowMondal={setShowMondal}
            handleClick={handleClick}
          />
        )}
      </AdminMui>
    </>
  ) : (
    <>{history.push("/admin/login")}</>
  );
};

export default CostosFinanciamiento;
